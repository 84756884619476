import $ from 'jquery';

(() => {
  // PCサブメニューリンク、フッターメニューリンク
  $('.header__dropdwm--sub-li li a , .header__sp--dropdwm--li li a , .sitemap__item--li li a, .link__anc').click(function (e) {
    location.href = $(this).attr('href');
    ancClick();
    e.preventDefault();
  });


  if(!$('.accordion__title').length) return false;

  $('.accordion__contents').hide();
  $('.accordion__title').each(function (index, element) {
    let title = $(this);
    let content = $(this).next('.accordion__contents');

    title.click (function (e) {
        // メニューとフッターからのトリガーの場合
        if(e.isTrigger) {
            if (content.is(':hidden')) {
                content.slideToggle(500);
                title.toggleClass('accordion__title--open');
            }
        // 直接タイトルクリック
        } else {
            content.slideToggle(500);
            title.toggleClass('accordion__title--open');
        }
        e.preventDefault();
    });

  });

  const ancInit = function() {

    ancClick();
    // var hash = location.hash;
    // if(hash) {
    //   var target = $('[data-anc-id="'+hash+'"]');
    //   if(!target.length) return;
    //   $(window).on('load',function(){
    //     target.trigger('click');
    //     ancScroll(target);
    //   });

    // }
  }

  const ancClick = function() {

    // SPハンバーガーメニュー閉じる
    if($('body').hasClass('header__active')) $('.header__nav-ico').trigger('click');

    var hash = location.hash;
    var target;

    if(hash) {
        switch (hash){
            // 大学給付奨学制度サブメニュー用
            case '#college-about':
            case '#college-event':
            case '#college-abroad':
            case '#graduate-outline':
                target = $('[data-anc-id="'+hash+'"]');
                if(!target.length) return;
                $('[data-anc-id="#college-system"]').trigger('click');
                ancScroll(target);
                break;
            default:
                target = $('[data-anc-id="'+hash+'"]');
                if(!target.length) return;
                target.trigger('click');
                ancScroll(target);
        }
    }
  }

  const ancScroll = function(target) {
    setTimeout(function(){
      var position = target.offset().top;
      var headerHeight = $('.header__sp').innerHeight();
      position = position - headerHeight;
      $('body,html').animate({scrollTop:position}, 300, 'swing');
    } , 200)
  }

  ancInit();


})();


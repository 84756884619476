import $ from 'jquery';

(() => {

  // UA処理
  $(window).on('load resize', function() {
    var windowWidth = window.innerWidth;
    if ( window.document.body.id === 'top','form' ) {
      $(window).on('load resize', function() {
        var windowWidth = window.innerWidth;

      });
      // console.log(windowWidth);
        if (windowWidth <= 979){
          // トップSP表示
          $(".header").css({
                "display": "none",
          });
          $(".header__sp").css({
                "display": "table",
          });
          $(".header__nav-ico").css({
                "display": "inline-block",
          });
          $(".block__bread").css({
                "margin-top": "70px",
          });
      } else{
          // トップPC表示
          $(".header").css({
                "display": "table",
          });
          $(".header__sp").css({
                "display": "none",
          });
          $(".header__nav-ico").css({
                "display": "none",
          });
          $(".block__bread").css({
                "margin-top": "0",
          });
        }
    }
      else{

        // 下層の場合

        var ua = navigator.userAgent;
        $(".block__bread").css({
          "min-width": "1200px",
          "box-sizing": "border-box",
        });
        $("footer").css({
          "min-width": "1200px",
        });
        if ((ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0) && ua.indexOf('Mobile') > 0) {
            // スマートフォン用処理
            $(".header").css({
                "display": "none",
            });
            $(".header__sp").css({
                "display": "table",
                "min-width": "1200px",
            });
            $("body").css({
                // "min-width": "1200px",
            });
            $(".block__bread").css({
              "margin-top": "70px",
            });
        } else {
            // PC用処理
            $(".header").css({
                "display": "table",
                "min-width": "1200px"
            });
            $(".header__sp").css({
                "display": "none",
                "min-width": "initial",
            });
            $("body").css({
                "min-width": "initial",
            });
        }


      }
  })


  $(window).on('load resize', function() {

  var mql = window.matchMedia('screen and (max-width: 979px)');
    function checkBreakPoint(mql) {
      if (mql.matches) {
        // $(function() {
        // 　$('.header__nav-ico').unbind().click(function() {
        //   console.log("クリックした")
        //     $(this).toggleClass('active');
        //     $('.top').toggleClass('header__active');
        // 　if ($(this).hasClass('active')) {
        //     $('.header__nav').addClass('active');　 //クラスを付与
        //     $('.top').addClass('header__active');
        // 　} else {
        //     $('.header__nav').removeClass('active'); //クラスを外す
        //     $('.top').removeClass('header__active');
        // 　}
        // 　});
        //   // $(window).scroll(function(){
        //   //   $('.header__nav-ico').removeClass('active');
        //   //   $('.header__nav').removeClass('active');
        //   // });
        // });

      } else {



        $('.header__dropdwm').hover(function(){
          // console.log("ホバーした");
            // $(".header__dropdwm--show:not(:animated)", this).slideDown();
            $(".header__dropdwm--show", this).stop().slideDown();
            $(".header__dropdwm--show", this).css({
              "display": "flex"
            });
            $("main").addClass("header__bg");
        }, function(){
            $(".header__dropdwm--show",this).stop().slideUp();
            $("main").removeClass("header__bg");
        });


        $('.header__dropdwm--tab-panel ul').css('display','none');
        $('.header__dropdwm--tab li').removeClass('tab_current');


      $('.header__dropdwm--tab li').hover(
        function(){
          // マウスが乗った時の処理
          var tab_num = $('.header__dropdwm--tab li').index(this);
          console.log(tab_num)
          // $('.header__dropdwm--tab-panel ul').eq(tab_num).css('display','block');
          $('.header__dropdwm--tab-panel ul').removeClass('content_current');
          $('.header__dropdwm--tab-panel ul').eq(tab_num).addClass('content_current');
          $(this).addClass('tab_current');
        }
      );


      }
    }
  // ブレイクポイントの瞬間に発火
  // mql.addListener(checkBreakPoint);

  // 初回チェック
  checkBreakPoint(mql);

      });


  $(function(){
    // $('.header__sp--dropdwm').hover(function(){
    //   console.log("ホバーしている");
    //     $(".header__sp--dropdwm-show:not(:animated)", this).slideDown();
    // }, function(){
    //     $(".header__sp--dropdwm-show",this).slideUp();
    // });


    $(".header__sp--dropdwm").on("click", function() {
        //クリックされた.accordion2の中のp要素に隣接する.accordion2の中の.innerを開いたり閉じたりする。
    $(this).next().slideToggle();
        //クリックされた.accordion2の中のp要素以外の.accordion2の中のp要素に隣接する.accordion2の中の.innerを閉じる
    $('.header__sp--dropdwm').not($(this)).next().slideUp();
    });

    $(".header__sp--dropdwm--cont p").on("click", function() {
        //クリックされた.accordion2の中のp要素に隣接する.accordion2の中の.innerを開いたり閉じたりする。
    $(this).next().slideToggle();
        //クリックされた.accordion2の中のp要素以外の.accordion2の中のp要素に隣接する.accordion2の中の.innerを閉じる
    $('.header__sp--dropdwm--cont p').not($(this)).next().slideUp();
    });

  });




$(function($){
    //クリックされるたびに呼ばれる
    $(".header__nav-ico").on("click", function() {
        var windowWidth = window.innerWidth;
        // クリックされた時、windowWidthが800以下だと実行する
        if (windowWidth <= 979) {

        }
        $('body').toggleClass('header__active');
        $(this).toggleClass('active');
        $('.header__sp--nav').slideToggle();

        if ($(".header__nav-ico").hasClass('active')) {

          $(document).on('click',   function(e) {
            if (!$(e.target).closest('.header__sp').length) {
              // フェードやスライドなどの処理方法を記述;
              $('.header__nav-ico').removeClass('active');
              $('body').removeClass('header__active');
              $('.header__sp--nav').slideUp();
            }
          });

        }
    });
});

// カレント
$(function() {
    $('.header__nav--li .header__nav--cont a').each(function(){
        var $href = $(this).attr('href');
        if(location.href.match($href)) {
            $(this).addClass('header__current');
        } else {
            $(this).removeClass('header__current');
        }
    });
});




})();

import $ from 'jquery';
import slick from 'slick-carousel';

(() => {



  $('.mainvisual__slick').slick({
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    adaptiveHeight: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    pauseOnDotsHover: false
  });



})();

import $ from 'jquery';

(() => {




  $(function(){
    // ★　任意のズレ高さピクセル数を入力　↓
    var headerHight = 40;
     // #で始まるアンカーをクリックした場合に処理
     $('a[href^="#"]').click(function() {
      // スクロールの速度
      var speed = 400; // ミリ秒
      // アンカーの値取得
      var href= $(this).attr("href");
      // 移動先を取得
      var target = $(href == "#" || href == "" ? 'html' : href);
      // 移動先を数値で取得
      var position = target.offset().top-headerHight; // ※　-headerHightでズレの処理
      // スムーズスクロール
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
     });
  });

  // タブ
  $(".tab li").click(function() {
    var num = $(".tab li").index(this);
    var index = $('.tab li').index(this);
    $('.tab li').removeClass('tab__current').eq(index).addClass('tab__current');
    $('.tab__cont div.tab__cont-box').removeClass('tab__show').eq(index).addClass('tab__show');

  });

  // タブコメント処理
  $(".tab li").hover(function() {
    var num = $(".tab li").index(this);
    var index = $('.tab li').index(this);
    $('.tab__cmt .tab__cmt-txt').removeClass('tab__cmt-show').eq(index).addClass('tab__cmt-show');

  },function(){
    $('.tab__cmt .tab__cmt-txt').removeClass('tab__cmt-show');
  });



//ダイレクトリンク
$(function() {
//location.hashで#以下を取得 変数hashに格納
  var hash = location.hash;
  //hashの中に#tab～が存在するか調べる。
  hash = (hash.match(/^#tab\d+$/) || [])[0];

   //hashに要素が存在する場合、hashで取得した文字列（#tab2,#tab3等）から#より後を取得(tab2,tab3)
　　　　if($(hash).length){
  var tabname = hash.slice(1) ;
  } else{
　　　　// 要素が存在しなければtabnameにtab1を代入する
　　　　var tabname = "tab1";}
  //コンテンツを一度すべて非表示にし、
  // $('.tab__cont div.tab__cont-box').css('display','none');
  $('.tab__cont div.tab__cont-box').removeClass('tab__show');

  //一度タブについているクラスselectを消し、
  $('.tab li').removeClass('tab__current');

  var tabno = $('.tab li#' + tabname).index();

  //クリックされたタブと同じ順番のコンテンツを表示します。
  //
  $('.tab__cont div.tab__cont-box').eq(tabno).addClass('tab__show');

  //クリックされたタブのみにクラスselectをつけます。
  $('.tab li').eq(tabno).addClass('tab__current')
    });


  // ドロップダウン
  $(function(){
    $(".block__dropdwn .block__dropdwn--btn").on("click", function() {
    $(".block__dropdwn--cont").slideToggle();
    });
  });

  $(function(){
      //.accordion2の中のp要素がクリックされたら
  $(".block__toggle-head").on("click", function() {
      //クリックされた.accordion2の中のp要素に隣接する.accordion2の中の.innerを開いたり閉じたりする。
  $(this).next().slideToggle();
  $(this).toggleClass('block__toggle-active');
      //クリックされた.accordion2の中のp要素以外の.accordion2の中のp要素に隣接する.accordion2の中の.innerを閉じる
  $('.block__toggle-head').not($(this)).next().slideUp();
  $('.block__toggle-head').not($(this)).removeClass('block__toggle-active');
  });
  });

  // フッターナビ
  $(function() {
      $('.block__pnav .list__link--arrow a').each(function(){
          var $href = $(this).attr('href');
          if(location.href.match($href)) {
              $(this).addClass('block__pnav-current');
          } else {
              $(this).removeClass('block__pnav-current');
          }
      });
  });

  // ページトップ
  $(function() {
    var topBtn = $('.com__ico--pagetop');
    topBtn.hide();
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
    //スクロールしてトップ
    topBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });
});




})();
